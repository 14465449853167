var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('Logo'),_c('b-card-title',{staticClass:"text-center mb-1"},[_vm._v(" Reset Password 🔒 ")]),_c('b-card-text',{staticClass:"text-center mb-2",class:{
          'text-success': _vm.success,
          'text-danger': _vm.error
        }},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('ValidationObserver',{ref:"simpleRules"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid': null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":"Email"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"New Password","label-for":"reset-password-new"}},[_c('ValidationProvider',{attrs:{"name":"Password","vid":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid': null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"reset-password-new","placeholder":"············"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"reset-password-confirm","label":"Confirm Password"}},[_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required|min:6|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.newPasswordConfirmation),callback:function ($$v) {_vm.newPasswordConfirmation=$$v},expression:"newPasswordConfirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"text-center"},[_c('Recaptcha',{on:{"getRecaptcha":_vm.setRecaptcha}})],1),_c('b-button',{attrs:{"block":"","type":"submit","variant":"primary"}},[_vm._v(" Set New Password ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Back to login ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }