









































































































































import { Component, Mixins } from 'vue-property-decorator'
import { required } from '@/services/Validation'
import { Auth } from '@/api'
import { FormHelper } from '@/mixins'

@Component({
  components: {
    Logo: () => import('@/components/Logo.vue'),
    Recaptcha: () => import('@/components/Recaptcha.vue')
  }
})
export default class ResetPassword extends Mixins(FormHelper) {
  userEmail = ''
  newPassword = ''
  newPasswordConfirmation = ''
  recaptcha = ''
  passwordFieldType = 'password'
  required = required
  success = false
  error = false
  message = ''

  mounted() {
    if (!this.$route.query.confirmationToken) {
      this.$router.replace({ name: 'login' })
    }
  }

  async validate() {
    const { userEmail, newPassword , newPasswordConfirmation, recaptcha } = this
    const errors = await Auth.resetPassword({
      email: userEmail,
      newPassword,
      newPasswordConfirmation,
      recaptcha,
      confirmationToken: this.$route.query.confirmationToken as string
    })

    if (errors.detail) {
      this.error = true
      this.message = errors.detail
      this.$root.$emit('resetCaptcha')
    } else {
      this.success = true
      this.message = errors
    }
  }
}
